body {
  margin: 0;
  padding: 0;
}

.emma-title {
  font-size: 32px;
  font-weight: bold;
  color: #1f95d3;
  line-height: 35px;
}

.emma-title-light {
  font-size: 28px;
  font-weight: bold;
  color: #999999;
}

.emma-subtitle {
  font-size: 22px;
  font-weight: bold;
  color: #1f95d3;
}

.emma-subtitle-light {
  font-size: 24px;
  font-weight: bold;
  color: #999999;
}

.emma-card-title {
  font-size: 24px;
  font-weight: normal;
  color: #999999;
}

.emma-subtitle1 {
  font-size: 18px !important;
  font-weight: bold;
  color: #999999;
}

.emma-font-light {
  font-size: 18px;
  color: #999999;
}

.emma-font-light-small {
  font-size: 14px;
  color: #999999;
}

.emma-font-bold-small {
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.emma-font-light-underlined {
  font-size: 18px;
  color: #999999;
  text-decoration: underline;
}

.emma-blue-bold {
  font-size: 18px;
  font-weight: bold;
  color: #1f95d3;
}

.emma-font-default-bold {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.emma-font-default {
  font-size: 18px;
  color: #000;
}

.emma-font-default-small {
  font-size: 16px;
  color: #000;
}

.emma-font-red {
  font-size: 18px;
  color: #F44237;
}

.emma-font-underlined {
  font-size: 18px;
  color: #000;
  text-decoration: underline;
}

.mat-button-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.centered-icon {
  display: flex !important;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.centered {
  justify-content: center;
  display: flex;
  align-items: center;
}

.full-width {
  width: 100%;
}

.align-text-center, .text-align-center {
  text-align: center;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle, .mat-radio-outer-circle {
  border-color: #999999 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #999999 !important;
}

.tooltip-wrap {
  white-space: pre-line;
}

.exchange-icon-container {
  margin-top: 15px;
  color: #3C4858;
  display: flex;
  align-items: center;
  justify-content: center;

  mat-icon {
    cursor: pointer;
    margin-right: 12px;
  }
}

.exchange-card-title-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-close-button {
  z-index: 100;
  position: absolute !important;
  top: 10px;
  right: 10px;
  font-size: 25px;
  color: #9d9d9d;
  justify-content: center;
  align-items: center;
  ng-icon {
    width: 35px;
    height: 35px;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1f95d3;
}

.icon-button {
  cursor: pointer;
  color: rgb(85, 85, 85);
}

.card {
  border: 1px solid rgba(180, 180, 180, 0.3);
  box-shadow: 0 6px 12px rgba(140,152,164,.075);
}

.emma-body {
  height: 100%;
  display: block;
  overflow: auto;
}

.emma-body-white {
  height: 100%;
  background-color: #fff;
  display: block;
  overflow: auto;
}


.fill-screen {
  width: 100%;
  height: 100%;
}

.fill-screen-user {
  width: 100%;
  height: 100%;
  margin-top: 57px;
}

.rounded-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-right: 20px;
  min-height: 40px;

  input {
    width: 100%;
    color: #4a5568;
    padding: 0.5rem 1rem;
    line-height: 1;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    overflow: visible;
    border: none;
  }

  &-icon {
    border-radius: 999999px;
    background-color: #1f95d3;
    width: 1.5rem;
    height: 1.5rem;
    max-width: 1.5rem;
    max-height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    color: white;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
    }
  }

  &-icon:hover {
    opacity: 0.7;
  }
}

.btn-icon {
  font-size: 23px !important;
  margin-right: 10px !important;
  margin-left: -10px !important;
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    position: relative;
    width: 25%;
    margin-top: -30px;
  }

  .not-found-title {
    @extend .emma-subtitle-light;
    width: 35%;
    margin-top: 30px;
  }

  span {
    width: 35%;
    @extend .emma-font-light;
  }
}

.empty-row {
  height: calc(100vh - 233px);
  td {
    cursor: default;
  }
}

.empty-row:hover {
  background-color: white !important;
}

.mat-paginator-container {
  justify-content: flex-start !important;
}

.mat-table-container {
  flex: 1;
  background-color: white;
  overflow: auto;

  table {
    box-shadow: none !important;
  }
}

.table-paginator-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.emma-table {
  width: 100%;
  //box-shadow: 0 0 #0000, 0 0 #0000,0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: none;
  color: rgba(17,24,39,1);
  font-size: .875rem;
  line-height: 1.25rem;

  thead {
    .mat-header-cell {
      background-color: #f3f4f6 !important;
    }

    .mat-sort-header-content {
      //text-transform: uppercase !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      color: rgb(60, 72, 88);
    }

    th {
      border-bottom: 1px solid rgba(0,0,0,.12);
    }
  }
  tbody {
/*    tr:nth-child(even){
      background-color: rgba(249,250,251, 1);
    }*/

    td {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      border-bottom: 1px solid rgba(0,0,0,.12);
    }

    tr:hover {
      cursor: pointer;
      background-color: rgba(243,244,246, 1);
    }
  }

  .cell-icon-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cell-block {
    display: flex;
    flex-direction: column;
  }


  .font-bold {
    font-weight: 500;
    color: rgba(17,24,39, 1);
    font-size: 15px;
    line-height: 20px;
  }

  .font-light {
    color: rgba(107,114,128, 1);
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .font-default {
    color: rgba(17,24,39, 1);
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .font-link {
    color: rgba(17,24,39, 1);
    font-size: .875rem;
    line-height: 1.25rem;
    text-decoration: underline;
  }

  .font-link:hover {
    color: rgba(17,24,39, .7);
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card {
  display: flex;
  flex-flow: column;
}

.card-body {
  flex: 1;
}

.btn {
  display: flex;
  align-content: center;
  align-items: center;
}

.ng-icon-default {
  font-size: 23px !important;
  width: 23px;
  height: 23px;
  color: #4a5568;

  svg {
    margin-bottom: -5px;
  }
}

.hover-hero {
  cursor: pointer;
}

.hover-hero:hover {
  opacity: .7;
}

.ng-icon-default-size {
  font-size: 23px !important;
  width: 23px;
  height: 23px;
}

.mat-column-select {
  width: 60px;
  .mat-checkbox-inner-container {
    margin-bottom: 3px;
  }
}


.alert {
  display: flex;

  span {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 10px;
  }
}

.page-container {
  height: 100%;
  overflow: auto;
}

.mat-menu-panel {
  background-color: white;
}

.mat-select-panel {
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-paginator-range-actions {
  .mat-paginator-navigation-previous, .mat-paginator-navigation-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-icon-button {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .mat-button-disabled {
    color: lightgray;
  }
}

@media screen and (max-width: 1900px) {
  html {
    transform: scale(0.8) !important;
    width: 125% !important;
    position: absolute !important;
    left: -12.5% !important;
    top: -12.5% !important;
    height: 125% !important;
    overflow-x: hidden;
  }

  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      //margin-top: 5.5%;
      //margin-left: 7%;

      .mat-menu-panel {
        margin-top: 5%;
        min-width: 200px;
      }
    }
  }

  tr.mat-header-row {
    height: 57px;
  }

  body {
    width: 100%;
    height: 100%;
  }

  .page-container {
    height: 100%;
    overflow: auto;
  }

  .empty-row {
    height: 100vh!important;
  }
}
@media screen and (min-width: 1900px) {
  main {
    height: 100vh !important;
  }
}

.emma-input-label-alt {
  line-height: 1.625;
  color: rgba(160,174,192, 1);
  font-weight: 700;
  font-size: 16px;
}

.emma-input-alt {
  width: 100%;
  color: rgba(74,85,104,1);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.25rem;
  border: 2px solid;
  border-radius: .25rem;
  border-color: rgba(237,242,247, 1);
  background-color: rgba(237,242,247,1);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emma-input-alt:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: #1f95d3;
  background-color: rgba(255,255,255, 1);
}

.emma-input-label {
  line-height: 1.625;
  color: rgba(74,85,104, 1);
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 700;
}

.emma-input {
  width: 100%;
  color: rgba(74,85,104,1);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
  padding-left: .75rem;
  padding-right: .75rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.25rem;
  border: 1px solid;
  border-radius: .25rem;
  border-color: rgba(237,242,247, 1);
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .emma-input {
    border: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px rgba(66,153,225,.5);
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: white;
  }

  .emma-input:focus {
    box-shadow: none;
  }

  .mat-icon-button {
    width: 24px;
    height: 24px;
  }

  .is-invalid {
    border-color: rgba(245,101,101,1)!important;
  }
}

.emma-input:focus {
  box-shadow: 0 0 0 3px rgba(66,153,225,.5);
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: white;
}

.is-invalid {
  border-color: rgba(245,101,101,1)!important;
}

.emma-input-error {
  color: rgba(245,101,101, 1);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}

.emma-input-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
}

.mat-row {
  height: 65px !important;
}

mat-dialog-container {
  position: relative;
}

.mobile-hidden {
  @media only screen and (max-width : 992px) {
    display: none;
  }
}

.emma-dropdown {
  &-fit {
    width: fit-content;
  }

  &-fullwidth {
    width: 100%;
  }

  &-container {
    position: relative;
  }

  &-fullwidth {
    width: 100%;
  }

  &-menu {
    z-index: 10000;
    position: absolute;
    display: flex;
    background-color: white;
    flex-flow: column;
    min-width: 14rem;
    margin-top: .5rem;
    border-radius: .375rem;
    color: #4a5568;
    white-space: nowrap;
    right: 0;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;

    &-margin {
      padding-top: .25rem;
      padding-bottom: .25rem;
    }

    box-shadow: 0 0 0 0 #fff, 0 0 0 calc(1px + 0px) rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    p {
      color: #4a5568;
      display: block;
      cursor: pointer;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      font-size: .875rem;
      line-height: 1.25rem;
      margin: 0;
      padding: 10px 1rem 0;
    }

    span {
      color: #4a5568;
      padding: .5rem 1rem;
      cursor: pointer;
      text-align: left;
      font-size: .875rem;
      line-height: 1.25rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span:hover {
      background-color: rgba(243, 244, 246, 1);
      color: rgba(55, 65, 81, 1);
    }
  }

  .rounded-button {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem 1rem;
    line-height: 1;
    color: #4a5568;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }
  }
}

.emma-dropdown-custom {
  &-fit {
    width: fit-content;
  }

  &-fullwidth {
    width: 100%;
  }

  &-container {
    margin-right: 20px;
    position: relative;
  }

  &-fullwidth {
    width: 100%;
  }

  &-menu {
    z-index: 10000;
    position: absolute;
    display: flex;
    background-color: white;
    flex-flow: column;
    min-width: 14rem;
    margin-top: .5rem;
    border-radius: .375rem;
    color: #4a5568;
    white-space: nowrap;
    right: 0;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;

    &-margin {
      padding-top: .25rem;
      padding-bottom: .25rem;
    }

    box-shadow: 0 0 0 0 #fff, 0 0 0 calc(1px + 0px) rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    p {
      color: #4a5568;
      display: block;
      cursor: pointer;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      font-size: .875rem;
      line-height: 1.25rem;
      margin: 0;
      padding: 10px 1rem 0;
    }

    span {
      color: #4a5568;
      cursor: pointer;
      text-align: left;
      font-size: .875rem;
      line-height: 1.25rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .rounded-button {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem 1rem;
    line-height: 1;
    color: #4a5568;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }
  }
}

.mat-icon-button {
  display: flex !important;
  justify-content: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  min-width: 150px;
  font-size: 14px;
  text-align: center;
  color: black;
  padding: 3px 8px;
  background: white;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  border: 1px solid rgba(180, 180, 180, 0.3);

  word-wrap: break-word;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent rgba(180, 180, 180, 0.3) transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.exchange-button-holder {
  display: flex;
  align-items: center;
}

.sort-style {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #3c4858;
}

.exchange-child-folder-overview .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0;
  padding-bottom: 16px;
}

.mat-icon {
  width: auto !important;
  height: auto !important;
}
