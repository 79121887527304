// Typography
//
// Font, line-height, and color for body text, headings, and more.

// https://www.google.com/design/spec/style/typography.html#typography-styles
// http://www.getmdl.io/styles/index.html


// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-base:             1rem !default;

$font-size-h1:                 3.3125rem;
$font-size-h2:                 2.25rem !default;
$font-size-h3:                 1.5625rem;
$font-size-h4:                 1.125rem !default;
$font-size-h5:                 1.0625rem !default;
$font-size-h6:                 0.75rem !default;
$font-paragraph:               14px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;


//
$display1-size: 7rem !default; // md display-4 112px was 6rem;
$display2-size: 3.5rem !default; // md display-3 56px was 5.5rem
$display3-size: 2.8125rem !default; // md display-2 45px was 4.5rem
$display4-size: 2.125rem !default; // md display-1 34px was 3.5rem
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
//
//$line-height-base:                1.5 !default;
//
$headings-margin-bottom:     ($spacer * 0.5) !default;
$headings-font-weight:         400 !default; // was 500

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold:    500 !default;
$font-weight-extra-bold: 700 !default;

$font-size-large:              1em          !default;
$font-size-large-navbar:       20px         !default;

$padding-input-vertical:        11px !default;
$padding-input-horizontal:      19px !default;

$padding-btn-vertical:         11px !default;
$padding-btn-horizontal:       22px !default;

$padding-base-vertical:        .5rem !default;
$padding-base-horizontal:      .7rem !default;

$padding-round-horizontal:     23px !default;

$padding-simple-vertical:      10px !default;
$padding-simple-horizontal:    17px !default;

$padding-large-vertical:       15px !default;
$padding-large-horizontal:     48px !default;

$padding-small-vertical:        5px !default;
$padding-small-horizontal:     15px !default;

$padding-label-vertical:        2px !default;
$padding-label-horizontal:     12px !default;

$margin-large-vertical:        30px !default;
$margin-base-vertical:         15px !default;

$margin-base-horizontal:       15px !default;
