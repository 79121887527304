@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'legacy-indigo-pink.css';

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: mat.define-typography-config($font-family: '"Inter", sans-serif;'),
  density: 0,
));

@include mat.all-component-themes($my-theme);
@include mat.legacy-core();

 @import "bootstrap.css";

 @import "core/variables";
 @import "core/mixins";

 // Core Components
 @import "core/buttons";
 @import "core/checkboxes";
 @import "core/radios";
 @import "core/forms";
 @import "core/input-group";
 @import "core/images";
 @import "core/navbar";
 @import "core/type";
 @import "core/tabs";
 @import "core/popover";
 @import "core/dropdown";
 @import "core/togglebutton";
 @import "core/ripples";
 @import "core/footers";
 @import "core/sidebar-and-main-panel";
 @import "core/fixed-plugin";
 @import "core/misc";

@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";

@import "core/responsive";
@import "core/custom";
