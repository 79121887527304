// Bootstrap brand color customization


/*     brand Colors              */
$emma-blue: #1f95d3;
$emma-light-blue: #9fdeff;
$emma-red: #f44237;
$emma-green: #00a31e;
$emma-light-green: #6fc07d;
$emma-grey: #999999;
$emma-white: #ffffff;

$brand-primary:              $emma-blue !default;
$brand-info:                 $cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;
$brand-blue:                 $emma-blue !default;
$brand-light-blue:           $emma-light-blue !default;
$brand-red:                  $emma-red !default;
$brand-green:                $emma-green !default;
$brand-light-green:          $emma-light-green !default;
$brand-grey:                 $emma-grey !default;
